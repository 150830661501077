<template>

	<v-card :data-color="color" class="card pa-4" outlined tile flat>

		<div class="card__body d-flex flex-column h-100 justify-space-between">

			<v-card-title  class="card__body__title">
				{{ topic.label }}
			</v-card-title>

			<v-card-text class="body-1 card__body__description">
				{{ topic.description }}
			</v-card-text>

			<v-card-actions class="align-self-end">
				<a @click="$emit('click', topic)"
					class="black--text font-weight-normal d-flex">
					<v-icon class="black--text material-icons-round rounded-circle v-card__actions__arrow">
						arrow_forward
					</v-icon>

					<span class="ml-1 hover__underline">
						Explore Surveys
					</span>
				</a>
			</v-card-actions>

		</div>


	</v-card>

</template>

<script>
export default {
	name: 'TopicCard',

	props: {
		topic: {
			type: Object,
			required: true,
			default: () => ({})
		},
		color: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

@each $color, $variantes in $colors {
	.card[data-color="#{$color}"] {
    .card__body {

      .card__body__title {
        font-size: 1.5em;
        word-break: break-word;
        color: map-get($variantes, "06");

        &::after {
          content: "";
          position: absolute;
          width: 50%;
          height: 2px;
          bottom: 0;
          background-color: map-get($variantes, "06");
        }
      }

    }
  }
}
.card__body__description {
  font-family: 'Optimistic Text', 'Noto Sans Khmer', sans-serif !important;
  margin-top: 48px;
  color: $gray-600;
}

.v-card__title h2 {
  word-break: break-word;
}


.v-card__actions {
  padding: 16px !important;

  .v-card__actions__arrow {
    transform: scale(0.8);
    border: 1px solid #000
  }

}

</style>
