<template>

	<div class="carousel__container">

		<div class="carousel" ref="carousel" :style="{ gap: `0 ${gapBetweenItems}px` }">

			<slot></slot>

		</div>

	</div>

</template>

<script>

export default {
	name: 'Carousel',

	props: {
		gapBetweenItems: {
			type: Number,
			default: 16
		}
	},

	data () {
		return {
			currentPage: 1,
			observer: null,
			CAROUSEL_MARGINS: 10,
			numberOfPages: 0
		};
	},

	watch: {
		currentPage: function () {
			this.translate();
			this.onCarouselChange();
		}
	},

	mounted: function () {
		this.observer = new MutationObserver(this.onCarouselChange);

		this.observer.observe(
			this.$el,
			{ attributes: true, childList: true, subtree: true }
		);

		window.addEventListener('resize', this.onCarouselChange);
	},

	beforeDestroy: function () {
		this.observer.disconnect();
		window.removeEventListener('resize', this.onCarouselChange);
	},

	methods: {
		onCarouselChange () {
			this.numberOfPages = this.getNumberOfPages();
			this.$emit('canGoChange', { prev: this.canGoPrev(), next: this.canGoNext() });
		},
		reset () {
			this.currentPage = 1;
		},
		getNumberOfPages () {
			return Math.ceil(this.getCarouselSlides().length / this.getCurrentPageSlidesInfos().maxSlidesShown);
		},
		getCurrentPageSlidesInfos () {
			const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

			const margins = (viewportWidth / this.CAROUSEL_MARGINS) * 2;

			const carouselWidth = (viewportWidth - margins);

			return this.getCarouselSlides().reduce((acc, curr) => {
				const { width } = curr.getBoundingClientRect();

				const widthAndGap = width + this.gapBetweenItems;

				if ((acc.totalShownSlidesWidth + Math.floor(widthAndGap)) <= carouselWidth || (acc.totalShownSlidesWidth + Math.floor(width)) <= carouselWidth) {
					acc.totalShownSlidesWidth += widthAndGap;
					acc.maxSlidesShown += 1;
				}

				return acc;
			}, { totalShownSlidesWidth: 0, maxSlidesShown: 0 });
		},
		getCarouselSlides () {
			const { children } = this.$refs['carousel'] || {};

			return Object.values(children);
		},
		translate () {
			const { totalShownSlidesWidth } = this.getCurrentPageSlidesInfos();

			this.$refs['carousel'].style.transform = `translateX(calc(-${totalShownSlidesWidth * (this.currentPage - 1)}px))`;
		},
		onNext () {
			this.currentPage = this.isEndOfCarousel ? 1 : this.currentPage + 1;
		},
		onPrev () {
			if (this.canGoPrev()) this.currentPage--;
		},
		canGoPrev () {
			return this.currentPage > 1;
		},
		canGoNext () {
			return this.numberOfPages > 1;
		}
	},

	computed: {
		isEndOfCarousel () {
			return (this.currentPage + 1) > this.numberOfPages;
		}
	}

};

</script>

<style lang="scss" scoped>
  @import "@/scss/_colors.scss";

  .carousel__container {
    width: 100%;
    overflow: hidden;

    .carousel {
      display: flex;
      transition: all 0.2s linear;

      > * {
        flex-shrink: 0;
      }
    }

  }

</style>
