<template>
	<HomeCarousel
		title="YOUR WORKSPACE"
		subTitle="Browse recent documents"
		:defaultActiveTab="activeTab"
		:tabs="tabs"
		@changeTab="changeTab($event)">

		<div  v-if="hasFetchedDocuments && !getRecentDocuments.length" class="card__container">
			<EmptyCard :text="getEmptyCard" />
		</div>

		<div v-for="({ type, foreignIdField, ...rest }) in getRecentDocuments" :key="rest.document[foreignIdField]" class="card__container">

			<DocumentBadge :text="getBadgeTextFromDocumentType(type)"/>

			<component
				:is="getComponentFromDocumentType(type, rest)['component']"
				v-bind="getComponentFromDocumentType(type, rest)['props']"
				v-on="getComponentFromDocumentType(type, rest)['methods']"  />

		</div>

	</HomeCarousel>
</template>

<script>
import HomeCarousel from '../components/HomeCarousel.vue';
import SurveyCard from '../components/SurveyCard.vue';
import FileCard from './FileCard.vue';
import ExperienceCard from './ExperienceCard.vue';
import { STEPS } from '@/store/general';
import EmptyCard from './EmptyCard.vue';
import DocumentBadge from './DocumentBadge.vue';

const { mapGetters, mapActions, mapMutations } = require('vuex');
const { RECENT_DOCUMENT_TYPES } = require('@/constants/types');

const TABS = {
	ALL: 'ALL',
	SURVEYS: 'SURVEYS',
	COLLATERALS: 'COLLATERALS',
	REPORTS: 'REPORTS'
};

export default {
	name: 'HomeRecentDocumentsCarousel',

	components: {
		HomeCarousel,
		SurveyCard,
		FileCard,
		ExperienceCard,
		EmptyCard,
		DocumentBadge
	},

	data () {
		return {
			hasFetchedDocuments: false,
			activeTab: TABS.ALL,
			tabs: [
				{ id: TABS.ALL, title: 'All documents' },
				{ id: TABS.REPORTS, title: 'Custom collateral' },
				{ id: TABS.SURVEYS, title: 'Surveys' },
				{ id: TABS.COLLATERALS, title: 'Reports' }
			]
		};
	},

	async mounted () {
		await this.loadRecentDocuments();
		this.hasFetchedDocuments = true;
	},

	methods: {
		...mapMutations('general', [
			'changeStep'
		]),
		...mapActions('home', [
			'loadRecentDocuments',
			'onSelectPreviewFromHome',
			'onSelectExport'
		]),
		...mapActions('talkingPoints', [
			'selectSurvey'
		]),
		...mapActions('report', [
			'restoreBuildExperienceSession'
		]),

		onSelectSurvey ({ surveyId }) {
			this.selectSurvey(surveyId);
			this.changeStep(STEPS.TALKING_POINTS);
		},
		onLoadReport (report) {
			this.restoreBuildExperienceSession(report);
		},

		changeTab (tab) {
			this.activeTab = tab;
		},

		getBadgeTextFromDocumentType (type) {
			if (type === RECENT_DOCUMENT_TYPES.SURVEY) return 'Survey';
			if (type === RECENT_DOCUMENT_TYPES.COLLATERAL) return 'Report';
			if (type === RECENT_DOCUMENT_TYPES.REPORT) return 'Custom Collateral';
		},
		getComponentFromDocumentType (type, { document, viewedAt }) {
			if (type === RECENT_DOCUMENT_TYPES.SURVEY) {
				return {
					component: SurveyCard,
					props: { survey: document },
					methods: { onSelectSurvey: this.onSelectSurvey }
				};
			}

			if (type === RECENT_DOCUMENT_TYPES.COLLATERAL) {
				return {
					component: FileCard,
					props: { file: document, fileUrl: '/api/v1/methodology-collateral/thumbnail?thumbnailKey=' },
					methods: { preview: this.onSelectPreviewFromHome, download: this.onSelectExport }
				};
			}

			if (type === RECENT_DOCUMENT_TYPES.REPORT) {
				return {
					component: ExperienceCard,
					props: { report: document, viewedAt },
					methods: { onLoadReport: this.onLoadReport }
				};
			}

			return { component: '', props: { } };
		}
	},

	computed: {
		...mapGetters('home', [
			'allRecentDocuments',
			'recentSurveysDocuments',
			'recentCollateralsDocuments',
			'recentReportsDocuments'
		]),

		getRecentDocuments () {
			if (this.activeTab === TABS.ALL) return this.allRecentDocuments;
			if (this.activeTab === TABS.SURVEYS) return this.recentSurveysDocuments(6);
			if (this.activeTab === TABS.REPORTS) return this.recentReportsDocuments;
			if (this.activeTab === TABS.COLLATERALS) return this.recentCollateralsDocuments(6);

			return this.allRecentDocuments;
		},

		getEmptyCard () {
			if (this.activeTab === TABS.ALL) return 'Browse Surveys, open a Collateral or create a Custom Report to see recent documents populate';
			if (this.activeTab === TABS.SURVEYS) return 'Select a Survey in the Build Experience to see it populate';
			if (this.activeTab === TABS.REPORTS) return 'Create a Custom Report to see it saved here';
			if (this.activeTab === TABS.COLLATERALS) return 'Open a Collateral in the Content Library to see it populate';

			return 'No data available';
		}
	}
};

</script>

<style lang="scss" scoped>
.card__container {

  @media (min-width: 576px) { width: calc(100% - 16px); }
  @media (min-width: 768px) { width: calc((100% - 16px) / 2); }
  @media (min-width: 992px) { width: calc((100% - (16px * 2)) / 3); }
  @media (min-width: 1200px) { width: calc((100% - (16px * 3)) / 4); }

}
</style>
