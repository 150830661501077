<template>
	<HomeCarousel
		title="Recently added by Meta"
		:subTitle="`Find the newest reports and collateral for ${userMarket.countryName}`"
	>
		<div v-if="hasFetchedNewestCollaterals && !newestCollaterals.length" class="card__container">
			<EmptyCard text="No collaterals are available for your market" />
		</div>

		<div v-for="file in newestCollaterals" :key="file.fileId" class="card__container">
			<DocumentBadge text="Report" />
			<FileCard
				:file="file"
				fileUrl='/api/v1/methodology-collateral/thumbnail?thumbnailKey='
				@preview="onSelectPreviewFromHome"
				@download="onSelectExport"
			/>
		</div>
	</HomeCarousel>
</template>

<script>
import HomeCarousel from '@/components/HomeCarousel';
import FileCard from '@/components/FileCard';
import EmptyCard from '@/components/EmptyCard';
import DocumentBadge from '@/components/DocumentBadge';

const { mapState, mapGetters, mapActions } = require('vuex');

export default {
	name: 'HomeNewestCollateralsCarousel',

	components: {
		HomeCarousel,
		FileCard,
		EmptyCard,
		DocumentBadge
	},

	data () {
		return {
			hasFetchedNewestCollaterals: false
		};
	},

	computed: {
		...mapState({
			newestCollaterals: (state) => state.home.newestCollaterals
		}),
		...mapGetters('general', [
			'userMarket'
		])
	},

	watch: {
		async userMarket () {
			if (this.userMarket) {
				await this.loadNewestCollaterals();
				this.hasFetchedNewestCollaterals = true;
			}
		}
	},

	methods: {
		...mapActions('home', [
			'loadNewestCollaterals',
			'onSelectPreviewFromHome'
		]),
		...mapActions('contentLibrary', [
			'onSelectExport'
		])
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.card__container {

  @media (min-width: 576px) { width: calc(100% - 16px); }
  @media (min-width: 768px) { width: calc((100% - 16px) / 2); }
  @media (min-width: 992px) { width: calc((100% - (16px * 2)) / 3); }
  @media (min-width: 1200px) { width: calc((100% - (16px * 3)) / 4); }

}
</style>
