<template>

	<div>

		<div class="header__fixed">
			<Header />
		</div>

		<div class="header__bg__container">

			<div class="header__container">
				<Header isWhite />
			</div>

			<hr class="header__underline"/>

			<v-img
				position="center top"
				class="header__bg"
				src="@/assets/home/home-header-bg.png">

				<div class="header__bg__content">

					<div class="header__bg_description">
						<p>Find the most relevant talking points</p>
						<p>within our Build Experience and</p>
						<p>browse our Content Library to make</p>
						<p>your narrative stronger</p>
					</div>

					<div class="header__bg_actions">
						<v-icon class="rounded-circle">
							arrow_forward
						</v-icon>

						<span class="hover__underline">
							<router-link to="/build-experience">Explore Build Experience</router-link>
						</span>
					</div>

					<div class="header__bg_actions">
						<v-icon class="rounded-circle">
							arrow_forward
						</v-icon>

						<span class="hover__underline">
							<router-link to="/content-library">Browse Content Library</router-link>
						</span>
					</div>

				</div>

			</v-img>
		</div>

		<div class="home__content">

			<p class="welcome my-16">
				Welcome back, {{ userFirstname }}!
			</p>

			<HomeRecentDocumentsCarousel />

			<HomeTrendingTopicsCarousel />

			<HomeNewestCollateralsCarousel />

		</div>

	</div>
</template>


<script>
import Header from '@/components/layouts/Header';
import HomeRecentDocumentsCarousel from '../components/HomeRecentDocumentsCarousel.vue';
import HomeTrendingTopicsCarousel from '../components/HomeTrendingTopicsCarousel.vue';
import HomeNewestCollateralsCarousel from '../components/HomeNewestCollateralsCarousel.vue';

const { mapGetters } = require('vuex');

export default {
	name: 'Home',

	components: {
		Header,
		HomeRecentDocumentsCarousel,
		HomeTrendingTopicsCarousel,
		HomeNewestCollateralsCarousel
	},

	data () {
		return {};
	},

	computed: {
		...mapGetters('general', [
			'userFirstname'
		])
	}
};

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.home__content {
  margin: 0 10vw;

  .welcome {
    font-weight: bolder;
    font-size: clamp(1.38rem, calc(0.14rem + 1.92vw), 4rem);
    color: $meta-blue;
    margin: 0;
  }

}

.header__bg__container {
  z-index: 20;
}

.header__fixed {
  width: 100%;
  background: #fff;
  position: fixed;
  z-index: 10;
  border-bottom: 1px solid $light-gray-03;
  height: $header-height;
}

.header__container {
  z-index: 30;
  width: 100%;
  position: absolute;
}

.header__underline {
  top: $header-height;
  position: absolute;
  border: none;
  height: 1px;
  background-color: #fff;
  width: calc(100% - (32px * 2));
  margin-left: 32px;
  z-index: 20;
}

.header__bg {
  aspect-ratio: 21/7.6;

  &:after{
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-image: radial-gradient(circle, rgba(0,0,0,0) 17%, rgba(0,0,0,1) 100%);
    opacity: .7;
  }

}


.header__bg__content {
  position: absolute;
  top: calc(#{$header-height} + 2%);
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  color: #fff;
  font-weight: 400;
  margin-left: 10%;
  z-index: 1;

  @media (max-width: 576px) {
    gap: 0.2rem;
  }

  .header__bg_description {
    margin-bottom: 0.1rem;

    @media (max-width: 576px) {
      margin-bottom: 0.05rem;
    }

    p {
      font-size: clamp(1.38rem, calc(0.14rem + 1.92vw), 4rem);
      line-height: initial;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 0.85rem;
      }
      @media (max-width: 576px) {
        font-size: 0.7rem;
      }
    }

  }

  .header__bg_actions {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: clamp(0.67rem, calc(0.14rem + 0.88vw), 2.33rem);

    span a {
      color: #fff !important;
    }

    > i {
      color: #fff;
      border: 1px solid #fff;
      margin-right: 8px;
      font-size: 22px;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
      @media (max-width: 576px) {
        font-size: 0.7rem;
      }
    }

    .hover__underline::before {
      background: #fff;
    }

  }

}

</style>
