<template>

	<v-card class="card mx-auto" outlined tile flat>

		<div class="card__body d-flex flex-column justify-space-between">

			<v-img :src="`data:image/png;base64, ${report.previewAsBase64}`" />

			<v-card-actions>
				<a @click="$emit('onLoadReport', report)"
					class="black--text font-weight-normal d-flex">
					<v-icon class="black--text material-icons-round rounded-circle v-card__actions__arrow">
						arrow_forward
					</v-icon>

					<span class="ml-1 hover__underline v-card__actions__text">
						Open collateral of {{ viewedAtFormatted }}
					</span>
				</a>
			</v-card-actions>

		</div>

	</v-card>

</template>

<script>
export default {
	name: 'ExperienceCard',
	props: {
		report: {
			type: Object,
			default: () => ({})
		},
		viewedAt: {
			type: String,
			default: new Date().toISOString()
		}
	},

	computed: {
		viewedAtFormatted () {
			const date = new Date(this.viewedAt);

			const month = date.toLocaleString('en-EN', { month: 'long' });
			const day = date.getDate();

			return `${month} ${day}`;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.card__body {
  flex-grow: 1;
}

.v-card__actions {
  padding: 16px;

  .v-card__actions__arrow {
    transform: scale(0.8);
    border: 1px solid #000
  }

  .v-card__actions__text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

}

</style>
