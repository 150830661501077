<template>
	<div class="document-badge">
		{{ text }}
	</div>
</template>

<script>
export default {
	name: 'DocumentBadge',

	props: {
		text: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.document-badge {
  position: absolute;
  z-index: 2;
  background: #fff;
  border-radius: 9999px;
  padding: 2px 12px;
  font-weight: 400;
  font-size: 0.9em;
  top: 8px;
  left: 8px;
  color: $meta-gray;
  border: 1px solid $gray-100;
}
</style>

