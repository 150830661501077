<template>

	<HomeCarousel
		title="Trending topics"
		:subTitle="`Explore Trending Topics in ${userMarket.countryName}`">

		<div v-if="hasFetchedMarketTrendings && !mostTrendingTopics.length" class="card__container">
			<EmptyCard text="Select Talking Points among the Surveys in The Build Experience to see Topics populate here" />
		</div>

		<div v-for="({ document }, index) in mostTrendingTopics" :key="document.id" class="card__container">

			<TopicCard
				:topic="document"
				:color="COLORS[index % COLORS.length]"
				@click="onSelectTopic" />

		</div>

	</HomeCarousel>

</template>


<script>
import HomeCarousel from '../components/HomeCarousel.vue';
import TopicCard from '../components/TopicCard.vue';
import EmptyCard from './EmptyCard.vue';

const { mapGetters, mapActions, mapMutations } = require('vuex');

const COLORS = [
	'yellow',
	'green',
	'blue',
	'red',
	'purple',
	'navy',
	'indigo',
	'teal',
	'orange'
];

export default {
	name: 'HomeTrendingTopicsCarousel',

	components: {
		HomeCarousel,
		TopicCard,
		EmptyCard
	},

	data () {
		return {
			COLORS,
			hasFetchedMarketTrendings: false
		};
	},

	async mounted () {
		await this.loadMarketTrendings();
		this.hasFetchedMarketTrendings = true;
	},

	methods: {
		...mapMutations('surveys', [
			'setInitialFilters'
		]),
		...mapActions('home', [
			'loadMarketTrendings'
		]),
		onSelectTopic (topic) {
			this.setInitialFilters({ topics: [topic], markets: [this.userMarket] });
			this.$router.push({ path: '/build-experience' });
		}
	},

	computed: {
		...mapGetters('home', [
			'mostTrendingTopics'
		]),
		...mapGetters('general', [
			'userMarket'
		])
	}
};

</script>

<style lang="scss" scoped>
@import "@/scss/_colors.scss";

.card__container {

  @media (min-width: 576px) { width: calc(100% - 16px); }
  @media (min-width: 992px) { width: calc((100% - 16px) / 2); }
  @media (min-width: 1200px) { width: calc((100% - (16px * 2)) / 3); }

}
</style>
