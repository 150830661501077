<template>

	<div class="mb-16">

		<div class="d-flex mb-8">

			<div class="titles mr-16">
				<p class="title"> {{ title }} </p>

				<p class="subtitle"> {{ subTitle }} </p>
			</div>

			<div class="tabs d-flex align-end justify-space-between">
				<div v-for="tab in tabs" :key="tab.id" @click="changeTab(tab.id)" :class="{'_active': tab.id === activeTab}">
					{{ tab.title }}
				</div>
			</div>

			<div class="nav d-flex align-end">
				<div @click="onPrev" class="rounded-circle">
					<v-icon class="nav-btn" :class="{'_disabled': !canGo.prev}">
						arrow_back_ios_new
					</v-icon>
				</div>

				<div @click="onNext" class="rounded-circle">
					<v-icon class="nav-btn" :class="{'_disabled': !canGo.next}">
						arrow_forward_ios
					</v-icon>
				</div>

			</div>

		</div>

		<Carousel ref="carousel" @canGoChange="canGoChange($event)">
			<slot></slot>
		</Carousel>

	</div>

</template>

<script>
import Carousel from '../components/Carousel.vue';

export default {
	name: 'HomeCarousel',

	components: {
		Carousel
	},

	props: {
		title: {
			type: String,
			required: true
		},
		subTitle: {
			type: String,
			required: true
		},
		defaultActiveTab: {
			type: String,
			default: ''
		},
		tabs: {
			type: Array,
			default: () => [],
			required: false
		}
	},

	data () {
		return {
			canGo: { prev: false, next: false },
			activeTab: this.defaultActiveTab
		};
	},

	methods: {
		onPrev () {
			this.$refs.carousel.onPrev();
		},
		onNext () {
			this.$refs.carousel.onNext();
		},
		canGoChange (canGo) {
			this.canGo = canGo;
		},
		changeTab (tabId) {
			this.activeTab = tabId;
			this.$refs.carousel.reset();
			this.$emit('changeTab', tabId);
		}
	}

};

</script>

<style lang="scss" scoped>
  @import "@/scss/_colors.scss";

  .titles {
    p {
      margin: 0;
      line-height: initial;
    }

    .title {
      text-transform: uppercase;
      color: $gray-600;
      font-weight: bold;
    }

    .subtitle {
      padding-top: .15em;
      line-height: 1em;
      color: $meta-gray;
      font-weight: bolder;
      font-size: clamp(1rem, calc(0.14rem + 1.92vw), 2rem) !important;
    }

  }


  .tabs {
    gap: 0 16px;

    > div {
      color: $gray-600;
      border-radius: 9999px;
      padding: 1px 16px;
      cursor: pointer;
      font-weight: 400;

      &:hover {
        color: $meta-gray;
      }

      &._active {
        color: $meta-blue;
        background-color: $blue-100;
      }
    }
  }

  .nav {
    gap: 0 8px;
    margin-left: auto;

    > div {
      cursor: pointer;

      &:first-child .nav-btn{
        padding-right: 3px;
      }

      &:last-child .nav-btn{
        padding-left: 3px;
      }
    }


    .nav-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $gray-600;
      font-size: 1.2em;
      border: 1px solid $gray-600;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      overflow: hidden;


      &._disabled {
        cursor: initial;
        color: $gray-300;
        border: 1px solid $gray-300;
      }
    }
  }

</style>
